/* ==========================================================================
Section Styles
========================================================================== */

//Sections
.section {
    &.section-light-grey {
        background-color: $light-grey;
    }
    &.section-feature-grey {
        background-color: $section-grey;
    }
    &.section-secondary {
        background-color: $secondary;
    }
    &.section-small {
        height: 30vh !important;
    }
    &.section-half {
        height: 75vh !important;
    }
    .title, .subtitle {
        // font-family: 'Noto Serif JP';
        font-family: 'Open Sans', sans-serif;
    }
    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

//Titles
.title-wrapper {
    max-width: 500px;
    margin: 0 auto;
    .title, .subtitle {
        font-family: 'Open Sans', sans-serif;
        // font-family: 'Libre Baskerville', serif;
    }
    .subtitle {
        &.is-muted {
            color: $muted-grey;
        }
    }
}

//Divider
.divider {
    height: 3px;
    border-radius: 50px;
    background: $secondary;
    width: 60px;
    &.is-centered {
        margin: 0 auto;
    }
}

//Wrapper
.content-wrapper {
    padding: 60px 0;
}


//Pulled image
img.pushed-image {
    margin-top: -29vh;
}

//Icon box
.media.icon-box {
    border-top: none !important;
    .media-content .content p {
        span {
            display: block;
        }
        .icon-box-title {
            color: $blue-grey;
            font-size: 1.2rem;
            font-weight: 600;
        }
        .icon-box-text {
            color: $title-grey;
            font-size: 1rem;
            font-weight: 400;
        }
    }
}
